import React, { useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import AllStrageRules from '../strage/AllStrageRules'

export default function RecSetting(props) {
    const [open, setOpen] = React.useState(false);
    const [sort, setSort] = React.useState(localStorage.getItem('mat_search_sort') != null ? localStorage.getItem('mat_search_sort') : '0');
    const [light, setLight] = React.useState(localStorage.getItem('mat_search_light') != null ? localStorage.getItem('mat_search_light') : '2');
    const [count, setCount] = React.useState(localStorage.getItem('mat_search_count') != null ? localStorage.getItem('mat_search_count') : '0');
    const [nowRows, setNowRows] = React.useState(localStorage.getItem('mat_search_rules') != null ? JSON.parse(localStorage.getItem('mat_search_rules')) : [])

    const clearRules = () => {
        setNowRows([]);
    };


    const handleClose = () => {
        setOpen(false);
        if (props.close != null) props.close(false)
        // if(props.refresh != null) props.refresh()
    };

    const handleOKClose = () => {
        localStorage.setItem("mat_search_sort", sort)
        localStorage.setItem("mat_search_light", light)
        localStorage.setItem("mat_search_count", count)
        localStorage.setItem("mat_search_rules", JSON.stringify(nowRows))

        setOpen(false);
        if (props.close != null) props.close(false)

        if (props.refresh != null) {
            props.refresh()
        }
    };

    useEffect(() => {
        if (props.open) setOpen(true)
        else setOpen(false)
    }, [props.open]);


    const updateRule = (id, data, enabled) => {
        if (enabled) {
            const rules = nowRows.filter(item => item.id !== id);
            const finalRules = rules.concat(data)
            setNowRows(finalRules)
        } else {
            const rules = nowRows.filter(item => item.id !== id)
            setNowRows(rules)
        }
    };

    return (
        <div>
            <Dialog
                fullWidth={true}
                maxWidth={'md'}
                open={open}
                onClose={handleClose}
            >
                <DialogTitle id="alert-dialog-title">
                    {"搜尋設定"}
                    <Typography color="red" variant="caption" sx={{ fontWeight: 'normal', alignSelf: 'center', ml: 1 }}>VIP專屬</Typography>
                </DialogTitle>
                <DialogContent  sx={{pl: 1, pr: 1}}>

                    <Stack spacing={3} direction="column" sx={{ p: 2 }}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">匹配程度</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={sort}
                                label="匹配程度"
                                
                                onChange={e => setSort(e.target.value)}
                            >
                                <MenuItem value={'0'}>{'全部顯示'}</MenuItem>
                                <MenuItem value={'95'}>{'95以上'}</MenuItem>
                                <MenuItem value={'90'}>{'90以上'}</MenuItem>
                                <MenuItem value={'85'}>{'85以上'}</MenuItem>
                                <MenuItem value={'80'}>{'80以上'}</MenuItem>
                                <MenuItem value={'75'}>{'75以上'}</MenuItem>
                                <MenuItem value={'70'}>{'70以上'}</MenuItem>
                                <MenuItem value={'65'}>{'65以上'}</MenuItem>
                                <MenuItem value={'60'}>{'60以上'}</MenuItem>
                                <MenuItem value={'55'}>{'55以上'}</MenuItem>
                                <MenuItem value={'50'}>{'50以上'}</MenuItem>
                                <MenuItem value={'45'}>{'45以上'}</MenuItem>
                                <MenuItem value={'40'}>{'40以上'}</MenuItem>
                                <MenuItem value={'35'}>{'35以上'}</MenuItem>
                                <MenuItem value={'30'}>{'30以上'}</MenuItem>
                                <MenuItem value={'20'}>{'20以上'}</MenuItem>
                                <MenuItem value={'10'}>{'10以上'}</MenuItem>
                            </Select>
                        </FormControl>

                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">動作</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={light}
                                label="動作"
                                onChange={e => setLight(e.target.value)}
                            >
                                <MenuItem value={'2'}>{'全部顯示'}</MenuItem>
                                <MenuItem value={'0'}>{'做多'}</MenuItem>
                                <MenuItem value={'1'}>{'做空'}</MenuItem>
                            </Select>
                        </FormControl>

                    </Stack>

                    <Divider sx={{ m: 2 }}>
                        <Chip label="股票設定" />
                    </Divider>
                    <AllStrageRules updateRule={updateRule} rows={nowRows} />

                </DialogContent>
                <DialogActions>
                    <Button onClick={clearRules}>清除規則</Button>
                    <Button onClick={handleClose}>取消</Button>
                    <Button disabled={localStorage.getItem("noad") != "1"} onClick={handleOKClose} autoFocus>
                        確定
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
