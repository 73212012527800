import React, { useEffect, useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { ConstructionOutlined, Lightbulb, PropaneTwoTone } from '@mui/icons-material';
import FavoriteBorderOutlined from '@mui/icons-material/FavoriteBorderOutlined'
import Favorite from '@mui/icons-material/Favorite'
import { getId } from '../util/ID'

import Avatar from '@mui/material/Avatar';
import { deepOrange, green } from '@mui/material/colors';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import RecBase from './RecBase'
import ButtonGroup from '@mui/material/ButtonGroup';
import KChart from './KChart'
import KChart1 from './KChart1'

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { grey } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import PriceChart from './PriceChart';
import Grid from '@mui/material/Grid';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#242424",
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

export default function RecItem(props) {
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const color = grey[400];

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openSetting, setOpenSetting] = React.useState(false);

    const openMenu = Boolean(anchorEl);


    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const openDetail = (sid1, sid2) => {
        if (props.openDetail != null) {
            props.openDetail(sid1, sid2)
        }
    }

    const openDetail2 = (sid1) => {
        if (props.openDetail != null) {
            props.openDetail2(sid1)
        }
    }

    const getColor = () => {
        if (props.row != null && props.row.price != null) {
            if (props.row.price.i > 0) return "red"
            else if (props.row.price.i < 0) return "green"
            else return null
        } else return null
    }

    const getInv = () => {
        if (props.row != null && props.row.price != null) {
            let pp = ((props.row.price.i * 100) / (props.row.price.p - props.row.price.i)).toFixed(1)

            if (props.row.price.i > 0) return "▲" + props.row.price.i + ' (' + pp + '%)'
            else if (props.row.price.i < 0) return "▼" + props.row.price.i + ' (' + pp + '%)'
            else return props.row.price.i + '元' + ' (' + pp + '%)'
        } else return '▲0元'
    }

    const getColor1 = (row) => {
        if (row != null && row.price != null) {
            if (row.price.i > 0) return "red"
            else if (row.price.i < 0) return "green"
            else return null
        } else return null
    }

    const getInv1 = (row) => {
        if (row != null && row.price != null) {
            let pp = ((row.price.i * 100) / (row.price.p - row.price.i)).toFixed(1)
            if (row.price.i > 0) {
                return "▲" + row.price.i + ' (' + pp + '%)'
            } else if (row.price.i < 0) {
                return "▼" + row.price.i + ' ('+ pp + '%)'
            } else return row.price.i + ' (' + pp + '%)'
        } else return '▲0'
    }


    const clickLove = () => {
        let param = {
            sn: getId(),
            sid: props.row.id,
        }

        if (localStorage.getItem("match_lic") != null) {
            param['lic'] = localStorage.getItem("match_lic")
        }

        fetch("/mat/v1/love/clicklove", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result != null && result.status === 0) {
                        // if(props.refresh != null) props.refresh()
                        if (props.updateLove != null) props.updateLove(props.row.id)
                    }
                }
            );
    }

    const handleMenuChange = (newValue) => {
        setAnchorEl(null);
        setOpenSetting(true)
    };

    const getStatus = (status) => {
        if (status === 0) return <Button sx={{ height: 45, whiteSpace:"nowrap"}} size="large" fullWidth disableElevation color="error" variant="contained">做多</Button>
        else if (status === 1) return <Button sx={{ height: 45, whiteSpace:"nowrap"}} size="large" fullWidth disableElevation color="success" variant="contained">做空</Button>
        else return <Button  sx={{ height: 45, whiteSpace:"nowrap"}} size="large" fullWidth disableElevation color="secondary" variant="contained">-</Button>
    }


    const getColor2 = (score) => {
        if (score >= 90) return "#538fa3"// "#3c7899"
        else return null
    }

    return (
        <Stack spacing={0} sx={{ m: 1, mb: 0.5, p: 0, border: 2, borderColor: theme.palette.mode === "light" ? "#c1b5c7" : theme.palette.secondary.main, boxShadow: 0.5, borderRadius: '0px' }}>

            <Box display={'flex'}>
                <Stack direction='row' spacing={0.5} sx={{ flex: 1, p: 0, pl: 1, pt: 0.5, alignSelf: 'center' }} >
                    {props.row.islv ? <Favorite color="error" onClick={e => clickLove()} /> :
                        <FavoriteBorderOutlined onClick={e => clickLove()} />}
                    <Typography variant="h2" sx={{ fontSize: 35, fontWeight: 'bold', alignSelf: 'center', whiteSpace:"nowrap" }} onClick={e => openDetail2(props.row.id)}>{props.row.company.short_name.substr(0, 5)}</Typography>
                    <Stack direction="column" spacing={0} sx={{ alignSelf: "center" }} >
                        <Typography variant="caption" sx={{ fontWeight: 'normal' }} onClick={e => openDetail2(props.row.id)}>{props.row.id}</Typography>
                        <Typography variant="caption" sx={{ fontWeight: 'normal' }} onClick={e => openDetail2(props.row.id)}>{props.row.company.inductry.substr(0, 5)}</Typography>
                    </Stack>
                </Stack>
                <Stack direction='row' spacing={0.5} sx={{ pr: 1, alignSelf: 'center' }} onClick={e => openDetail2(props.row.id)}>
                    <Typography color={getColor()} variant="subtitle2" sx={{ fontWeight: 'normal', alignSelf: 'center' }}>{props.row.price != null ? props.row.price.p : "-"}</Typography>
                    <Typography color={getColor()} variant="subtitle2" sx={{ fontWeight: 'normal', alignSelf: 'center' }}>{getInv()}</Typography>
                </Stack>
            </Box>

            <Divider sx={{ bgcolor: (theme) => theme.palette.mode === "light" ? "#c1b5c7" : theme.palette.secondary.main }}
                style={{
                    border: "none",
                    height: 0.5,
                    margin: 0,
                }} />

{/* <PriceChart prices={row.prices} /> */}

            <TableContainer component={Paper} sx={{}}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="left" width="10%"  >匹配</StyledTableCell>
                            
                            {/* <StyledTableCell align="center" ></StyledTableCell> */}
                            <StyledTableCell align="left" >股票</StyledTableCell>
                            <StyledTableCell align="left" >訊號</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.row != null && props.row.l != null && props.row.l.map(row => (
                            <TableRow
                                
                                key={row.company.stock_no}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell sx={{padding:1}} width="10%" align="left" onClick={e => openDetail(props.row.id, row.company.stock_no)}>
                                    <Avatar sx={{ bgcolor: getColor2(row.r * 100), height: 45, width: 45, fontSize: 18 }} variant="rounded">
                                        {(row.r * 100).toFixed(0)}
                                    </Avatar>
                                </TableCell>
                                {/* <TableCell sx={{}} align='left'>
                                    <PriceChart prices={row.prices} />
                                </TableCell> */}
                                <TableCell align='left' onClick={e => openDetail2(row.company.stock_no)}>
                                    <Typography sx={{padding: 0}} variant="h5" fontSize={18}>{row.company.short_name} {row.company.stock_no}</Typography>
                                    <Stack direction='row' spacing={0.1} sx={{ pr: 1, alignSelf: 'center' }} onClick={e => setOpen(true)}>
                                        <Typography color={getColor1(row)} variant="subtitle2" sx={{ fontWeight: 'normal', alignSelf: 'center' }}>{row.price != null ? row.price.p : "-"}</Typography>
                                        <Typography color={getColor1(row)} variant="subtitle2" sx={{ fontWeight: 'normal', alignSelf: 'center' }}>{getInv1(row)}</Typography>
                                    </Stack>
                                </TableCell>

                                <TableCell onClick={e => openDetail(props.row.id, row.company.stock_no)}>{getStatus(row.status)}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>



        </Stack>

    );
}
