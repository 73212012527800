import React, { useEffect, useRef } from 'react';
import { Height } from '../util/ScreenHeight'
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import Pagination from '@mui/material/Pagination';
import { Divider } from '@mui/material';
import { getId } from '../util/ID'
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function CLanding(props) {
    const [page, setPage] = React.useState(1);
    const [total, setTotal] = React.useState(100)

    const [showEmpty, setShowEmpty] = React.useState(false);
    const [emptyMsg, setEmptyMsg] = React.useState('');
    const [result, setResult] = React.useState(null);
    const [menu, setMenu] = React.useState(null)
    const [loading, setLoading] = React.useState(false)

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const fetchData = () => {
    }

    useEffect(() => {
        fetchData()
    }, []);


    return (
        <Box style={{ flexDirection: 'column', position: 'absolute', width: '100%', top: Height.header, bottom: fullScreen ? 55 : 0, overflowY: 'scroll' }} sx={{}}>
            <section class="header4 cid-tffA6HUufJ mbr-fullscreen" id="header4-0">


                <div class="mbr-overlay" style={{opacity: 0.8, backgroundColor: "rgb(165, 73, 151)"}}></div>
                <div class="container">
                    <div class="mbr-row mbr-justify-content-center">
                        <div class="mbr-col-lg-10 mbr-col-md-12">
                            <h1 class="mbr-fonts-style mbr-section-title display-3"><strong>配對交易</strong></h1>
                            <p class="mbr-fonts-style mbr-text display-5">股價走勢越是一致的兩支股票，若發生各別漲幅異常不同時，則可放空漲幅大的股票，買進低漲幅的股票，來進行配對交易。</p>
                            <div class="mbr-section-btn"><a class="btn btn-lg mbr-editable-button btn-info display-7" onClick={e => props.setMenu(2)}>開始配對交易</a>
                                {/* <a class="btn btn-lg mbr-editable-button btn-white display-7"  onClick={e => props.setMenu(7)}>買斷成為VIP</a> */}
                                </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="features2 cid-tffD2E7WvX" id="features2-1">



                <div class="container">

                    <h1 class="mbr-fonts-style maintitle mbr-white align-center display-3"><strong>特色</strong></h1>

                    <div class="mbr-row mbr-justify-content-center">
                        <div class="mbr-col-lg-3 mbr-col-md-8">
                            <div class="block-1 block">
                                <h2 class="mbr-fonts-style block1 mbr-white display-4">
                                    <strong>匹配度</strong></h2>
                                <p class="mbr-fonts-style block1 mbr-white display-6">清楚的顯示與每一支股票匹配度最高的五檔<br />
                                </p>
                            </div>
                        </div>
                        <div class="mbr-col-lg-3 mbr-col-md-8">
                            <div class="block-2 block">
                                <h2 class="mbr-fonts-style block1 mbr-white display-4"><strong>多空時機</strong></h2>
                                <p class="mbr-fonts-style block1 mbr-white display-6">
                                    圖表上明確標示出多空操作的時機點</p>
                            </div>
                        </div>
                        <div class="mbr-col-lg-3 mbr-col-md-8">
                            <div class="block-3 block">
                                <h2 class="mbr-fonts-style block1 mbr-white display-4">
                                    <strong>準確搜尋</strong></h2>
                                <p class="mbr-fonts-style block1 mbr-white display-6">
                                    可以立即搜尋出當下正在發出訊號的股票</p>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <section class="features10 cid-tffMLOutvA" id="features10-8">




                <div class="container">
                    <div class="mbr-row mbr-justify-content-center">
                        <div class="card mbr-col-sm-12 mbr-col-md-12 mbr-col-lg-4">
                            <div class="card-wrapper">
                                <div class="card-img">
                                    <amp-img class="mobirise-loader" src="assets/images/-2022-08-22-233412-592x568.png" layout="responsive" width="349.95016901408445" height="335.763" alt="">
                                        <div placeholder="" class="placeholder">
                                            <div class="mobirise-spinner">
                                                <em></em>
                                                <em></em>
                                                <em></em>
                                            </div></div>

                                    </amp-img>
                                </div>
                                <div class="card-box">
                                    <h4 class="card-title mbr-fonts-style mbr-bold display-5">匹配%</h4>

                                    <p class="mbr-text mbr-fonts-style mbr-black display-6">透過數學運算找出股票之間相關係數高的，來實施配對交易。</p>

                                </div>
                            </div>
                        </div>

                        <div class="card mbr-col-sm-12 mbr-col-md-12 mbr-col-lg-4">
                            <div class="card-wrapper">
                                <div class="card-img">
                                    <amp-img class="mobirise-loader" src="assets/images/-2022-08-22-233646-593x565.png" layout="responsive" height="333.425" width="349.9487168141593" alt="">
                                        <div placeholder="" class="placeholder">
                                            <div class="mobirise-spinner">
                                                <em></em>
                                                <em></em>
                                                <em></em>
                                            </div></div>

                                    </amp-img>
                                </div>
                                <div class="card-box">
                                    <h4 class="card-title mbr-fonts-style mbr-bold display-5">訊號</h4>

                                    <p class="mbr-text mbr-fonts-style mbr-black display-6">每日可以方便看到可以做配對交易的標的有哪些。</p>

                                </div>
                            </div>
                        </div>

                        <div class="card mbr-col-sm-12 mbr-col-md-12 mbr-col-lg-4 last-child">
                            <div class="card-wrapper">
                                <div class="card-img">
                                    <amp-img class="mobirise-loader" src="assets/images/-2022-08-22-234119-586x823.png" layout="responsive" height="491.475" width="349.94453219927095" alt="">
                                        <div placeholder="" class="placeholder">
                                            <div class="mobirise-spinner">
                                                <em></em>
                                                <em></em>
                                                <em></em>
                                            </div></div>

                                    </amp-img>
                                </div>
                                <div class="card-box">
                                    <h4 class="card-title mbr-fonts-style mbr-bold display-5">多空標示</h4>

                                    <p class="mbr-text mbr-fonts-style mbr-black display-6">個股走勢圖上清楚的標示出多空的訊號位置。</p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            

            

            <section class="features13 cid-tffJ7zyMnz" id="features13-5">




                <div class="container">
                    <div class="mbr-row mbr-justify-content-center">
                        <div class="mbr-col-md-12 mbr-col-lg-10">
                            <h1 class="mbr-fonts-style main-title align-center mbr-white display-2"><strong>常見問與答</strong></h1>
                        </div>
                    </div>
                    <div class="mbr-row mbr-justify-content-center">
                        <div class="mbr-col-md-12 mbr-col-sm-12 mbr-col-lg-10">
                            <amp-accordion class="accordion">
                                
                                <section>
                                    <header class="mbr-section-title">
                                        <div class="fa-wrap">
                                            <div class="icon-fa-wrap">
                                                <span class="iconfont-wrapper wrapper-plus">
                                                    <div class="fa fa-plus amp-iconfont"><svg width="32" height="32" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg" fill="currentColor"><path d="M1600 736v192q0 40-28 68t-68 28h-416v416q0 40-28 68t-68 28h-192q-40 0-68-28t-28-68v-416h-416q-40 0-68-28t-28-68v-192q0-40 28-68t68-28h416v-416q0-40 28-68t68-28h192q40 0 68 28t28 68v416h416q40 0 68 28t28 68z"></path></svg></div>
                                                </span>
                                                <span class="iconfont-wrapper wrapper-minus">
                                                    <div class="fa fa-minus amp-iconfont"><svg width="32" height="32" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg" fill="currentColor"><path d="M1600 736v192q0 40-28 68t-68 28h-1216q-40 0-68-28t-28-68v-192q0-40 28-68t68-28h1216q40 0 68 28t28 68z"></path></svg></div>
                                                </span>
                                            </div>
                                            <div class="fa-wrap border-left">
                                                <h4 class="mbr-fonts-style mbr-white display-5"><strong>若使用上有問題或有建議該如何？</strong></h4>
                                            </div>
                                        </div>
                                    </header>
                                    <p class="mbr-fonts-style mbr-white display-5">可寫信到jookershop@gmail.com</p>
                                </section>

                                <section>
                                    <header class="mbr-section-title">
                                        <div class="fa-wrap">
                                            <div class="icon-fa-wrap">
                                                <span class="iconfont-wrapper wrapper-plus">
                                                    <div class="fa fa-plus amp-iconfont"><svg width="32" height="32" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg" fill="currentColor"><path d="M1600 736v192q0 40-28 68t-68 28h-416v416q0 40-28 68t-68 28h-192q-40 0-68-28t-28-68v-416h-416q-40 0-68-28t-28-68v-192q0-40 28-68t68-28h416v-416q0-40 28-68t68-28h192q40 0 68 28t28 68v416h416q40 0 68 28t28 68z"></path></svg></div>
                                                </span>
                                                <span class="iconfont-wrapper wrapper-minus">
                                                    <div class="fa fa-minus amp-iconfont"><svg width="32" height="32" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg" fill="currentColor"><path d="M1600 736v192q0 40-28 68t-68 28h-1216q-40 0-68-28t-28-68v-192q0-40 28-68t68-28h1216q40 0 68 28t28 68z"></path></svg></div>
                                                </span>
                                            </div>
                                            <div class="fa-wrap border-left">
                                                <h4 class="mbr-fonts-style mbr-white display-5">
                                                    <strong>資料都何時更新？</strong>
                                                </h4>
                                            </div>
                                        </div>
                                    </header>
                                    <p class="mbr-fonts-style mbr-white display-5">
                                        多空訊號的搜尋盤中股價（30分鐘延遲）變動隨即更新。匹配程度的更新每日０３：００更新一次.
                                    </p>
                                </section>

                                <section class="super-hide">
                                    <header class="mbr-section-title">
                                        <div class="fa-wrap">
                                            <div class="icon-fa-wrap">
                                                <span class="iconfont-wrapper wrapper-plus">
                                                    <div class="fa fa-plus amp-iconfont"><svg width="32" height="32" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg" fill="currentColor"><path d="M1600 736v192q0 40-28 68t-68 28h-416v416q0 40-28 68t-68 28h-192q-40 0-68-28t-28-68v-416h-416q-40 0-68-28t-28-68v-192q0-40 28-68t68-28h416v-416q0-40 28-68t68-28h192q40 0 68 28t28 68v416h416q40 0 68 28t28 68z"></path></svg></div>
                                                </span>
                                                <span class="iconfont-wrapper wrapper-minus">
                                                    <div class="fa fa-minus amp-iconfont"><svg width="32" height="32" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg" fill="currentColor"><path d="M1600 736v192q0 40-28 68t-68 28h-1216q-40 0-68-28t-28-68v-192q0-40 28-68t68-28h1216q40 0 68 28t28 68z"></path></svg></div>
                                                </span>
                                            </div>
                                            <div class="fa-wrap border-left">
                                                <h4 class="mbr-fonts-style mbr-white display-5">Lorem ipsum dolor</h4>
                                            </div>
                                        </div>
                                    </header>
                                </section>
                                <section class="super-hide">
                                    <header class="mbr-section-title">
                                        <div class="fa-wrap">
                                            <div class="icon-fa-wrap">
                                                <span class="iconfont-wrapper wrapper-plus">
                                                    <div class="fa fa-plus amp-iconfont"><svg width="32" height="32" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg" fill="currentColor"><path d="M1600 736v192q0 40-28 68t-68 28h-416v416q0 40-28 68t-68 28h-192q-40 0-68-28t-28-68v-416h-416q-40 0-68-28t-28-68v-192q0-40 28-68t68-28h416v-416q0-40 28-68t68-28h192q40 0 68 28t28 68v416h416q40 0 68 28t28 68z"></path></svg></div>
                                                </span>
                                                <span class="iconfont-wrapper wrapper-minus">
                                                    <div class="fa fa-minus amp-iconfont"><svg width="32" height="32" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg" fill="currentColor"><path d="M1600 736v192q0 40-28 68t-68 28h-1216q-40 0-68-28t-28-68v-192q0-40 28-68t68-28h1216q40 0 68 28t28 68z"></path></svg></div>
                                                </span>
                                            </div>
                                            <div class="fa-wrap border-left">
                                                <h4 class="mbr-fonts-style mbr-white display-5">Lorem ipsum dolor</h4>
                                            </div>
                                        </div>
                                    </header>
                                </section>
                            </amp-accordion>
                        </div>
                    </div>
                </div>
            </section>


            <section class="footer1 cid-tfg21pHPXu" id="footer1-c">



                <div class="container">
                    <div class="align-center mbr-p-1">
                        <p class="mbr-text mbr-white mbr-fonts-style display-7">
                            © Copyright 2025 喬可小舖股份有限公司 - All Rights Reserved
                        </p>
                    </div>
                </div>
            </section>



        </Box>
    );
}
